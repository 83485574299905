//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GlobalInput',
  components: {
    MaskedInput: () => import('vue-masked-input'),
  },
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '40px',
    },
    width: {
      type: String,
      default: '100%',
    },
    fontSize: {
      type: String,
      default: '14px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    styles() {
      const obj = {}
      this.height && (obj.height = this.height)
      this.width && (obj.maxWidth = this.width)
      this.fontSize && (obj.fontSize = this.fontSize)
      return obj
    },
    classes() {
      return [
        this.options.outline && 'input--outline',
        this.focus && 'input--focus',
        this.value && !this.focus && 'input--value',
        this.isError && 'input--error',
      ]
    },
    options() {
      return {
        outline: true,
        mask: '',
        maxLength: null,
        value: this.value,
        type: this.type,
        disabled: this.disabled,
        placeholder: this.placeholder,
        ...this.settings,
      }
    },
    isError() {
      if (this.errors) {
        return Array.isArray(this.errors)
          ? this.$t(this.errors[0])
          : this.$t(this.errors.message)
      }
      if (!this.rules || !this.rules.$dirty) return

      const keys = Object.keys(this.rules.$params).filter(
        (key) => !this.rules[key],
      )
      return keys.length
        ? this.$t(keys[0] === 'email' ? 'emailValidationText' : `${keys[0]}`, {
            value: this.label,
            number:
              this.rules.$params[keys[0]].min ||
              this.rules.$params[keys[0]].max,
          })
        : null
    },
    listeners() {
      const { input, focus, blur, change, ...rest } = this.$listeners
      return rest
    },
  },
  methods: {
    onInput(value) {
      if (this.options.mask) {
        this.$emit('input', value)
        return
      }
      if (this.errors) {
        this.$emit('changeError', this.id)
      }
      this.$emit('input', value.target.value)
    },
    onFocus(value) {
      this.focus = true
      this.$emit('focus', value)
    },
    onBlur(value) {
      this.focus = false
      this.$emit('blur', value)
    },
    onChange(value) {
      this.$emit('change', value)
    },
  },
}
